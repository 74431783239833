import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-scroll";
import Sticky from "react-stickynode";

const Anchor = ({ id }) => {
  const { formatMessage } = useIntl();

  return (
    <Link
      activeClass="active"
      href={`#${id}`}
      to={id}
      spy
      smooth
      offset={-56}
      duration={500}
    >
      {formatMessage({ id })}
    </Link>
  );
};

const ProductNav = ({ product }) => (
  <Sticky className="product-nav">
    {product.level && <p>{product.level?.title}</p>}
    <ul // eslint-disable-line
      role="list"
    >
      {product.presentation && (
        <li>
          <Anchor id="presentation" />
        </li>
      )}
      {product.includedAtTheCamp &&
        product.includedOnGravel &&
        product.includedBeyondGravel &&
        product.notIncluded && (
          <li>
            <Anchor id="benefits" />
          </li>
        )}
      {product.preparation && (
        <li>
          <Anchor id="preparation" />
        </li>
      )}
      {product.questions && (
        <li>
          <Anchor id="questions" />
        </li>
      )}
    </ul>
    <Link
      className="link"
      href="#booking"
      to="booking"
      smooth
      offset={-56}
      duration={500}
    >
      <FormattedMessage id="book" />
    </Link>
  </Sticky>
);

export default ProductNav;
