import * as React from "react";

const OnePercent = () => (
  <svg
    width={110}
    height={46}
    viewBox="0 0 110 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>One Percent for the Planet</title>
    <path
      d="M50.5046 13.1604H58.036V15.4537H53.1106V17.0434H57.5669V19.2063H53.1106V22.5681H50.5046V13.1604Z"
      fill="currentColor"
    />
    <path
      d="M58.9742 17.9033C58.9742 15.167 61.1632 12.978 64.0559 12.978C66.9747 12.978 69.1116 15.141 69.1116 17.8512V17.8773C69.1116 20.5875 66.9225 22.7766 64.0299 22.7766C61.1372 22.7766 58.9742 20.6136 58.9742 17.9033ZM66.4534 17.9033C66.4534 16.5221 65.4632 15.3234 64.0299 15.3234C62.5965 15.3234 61.6584 16.47 61.6584 17.8512V17.8773C61.6584 19.2324 62.6487 20.4312 64.0559 20.4312C65.5153 20.4312 66.4534 19.2585 66.4534 17.9033Z"
      fill="currentColor"
    />
    <path
      d="M70.5449 13.1604H75.0012C76.4345 13.1604 77.4508 13.5252 78.0763 14.1767C78.6235 14.724 78.9102 15.4537 78.9102 16.3658V16.3919C78.9102 17.8252 78.1545 18.7894 76.9818 19.2845L79.2229 22.5681H76.2L74.2976 19.7015H73.1509V22.5681H70.5449V13.1604ZM74.8969 17.6949C75.783 17.6949 76.3042 17.2518 76.3042 16.5743V16.5482C76.3042 15.8185 75.7569 15.4276 74.8969 15.4276H73.177V17.6949H74.8969Z"
      fill="currentColor"
    />
    <path
      d="M85.1125 15.4537H82.272V13.1604H90.533V15.4537H87.7186V22.5942H85.1125V15.4537Z"
      fill="currentColor"
    />
    <path
      d="M91.7839 13.1604H94.39V16.6785H97.7517V13.1604H100.358V22.5942H97.7517V19.0239H94.39V22.5942H91.7839V13.1604Z"
      fill="currentColor"
    />
    <path
      d="M102.078 13.1604H109.661V15.3755H104.658V16.8088H109.192V18.8676H104.658V20.353H109.713V22.5681H102.078V13.1604Z"
      fill="currentColor"
    />
    <path
      d="M50.4525 24.4705H54.8045C57.3584 24.4705 59.0263 25.7996 59.0263 28.0408V28.0668C59.0263 30.4643 57.176 31.7152 54.6742 31.7152H53.267V34.6079H50.4525V24.4705ZM54.5961 29.5262C55.5864 29.5262 56.2118 29.005 56.2118 28.1971V28.1711C56.2118 27.3111 55.6124 26.842 54.5961 26.842H53.267V29.5262H54.5961Z"
      fill="currentColor"
    />
    <path
      d="M60.199 24.4705H63.0135V32.1583H67.9389V34.634H60.2251L60.199 24.4705Z"
      fill="currentColor"
    />
    <path
      d="M72.6297 24.3923H75.34L79.666 34.6079H76.643L75.9133 32.7837H72.0043L71.2746 34.6079H68.3298L72.6297 24.3923ZM75.1054 30.6207L73.9848 27.728L72.8382 30.6207H75.1054Z"
      fill="currentColor"
    />
    <path
      d="M80.6562 24.4705H83.2883L87.4579 29.8389V24.4705H90.2464V34.6079H87.7707L83.4447 29.031V34.6079H80.6562V24.4705Z"
      fill="currentColor"
    />
    <path
      d="M92.0185 24.4705H100.175V26.868H94.8069V28.4056H99.6802V30.6207H94.8069V32.2364H100.253V34.634H92.0185V24.4705Z"
      fill="currentColor"
    />
    <path
      d="M104.163 26.9462H101.113V24.4705H110V26.9462H106.951V34.634H104.136L104.163 26.9462Z"
      fill="currentColor"
    />
    <path
      d="M31.2985 19.3885C31.2985 18.7631 31.8197 18.2419 32.4452 18.2419C33.0706 18.2419 33.5918 18.7631 33.5918 19.3885C33.5918 20.014 33.0706 20.5352 32.4452 20.5352C31.8197 20.5352 31.2985 20.014 31.2985 19.3885Z"
      fill="currentColor"
    />
    <path
      d="M39.1939 24.0271C38.5685 24.0271 38.0473 24.5483 38.0473 25.1737C38.0473 25.8252 38.5424 26.3204 39.1939 26.3204C39.8194 26.3204 40.3406 25.7992 40.3406 25.1737C40.3406 24.5483 39.8194 24.0271 39.1939 24.0271Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22.9851C0 10.2938 10.2938 0 22.9851 0C35.6764 0 45.9701 10.2938 45.9701 22.9851C45.9701 35.6764 35.6764 45.9701 22.9851 45.9701C10.2938 45.9701 0 35.6764 0 22.9851ZM18.6326 40.8623V13.1082L15.2969 13.0821L15.3229 8.5737L19.6489 4.7168H27.5973V40.8623H18.6326ZM40.8111 16.7559H38.9347L30.9082 27.7793H32.7845L40.8111 16.7559ZM29.8652 19.3885C29.8652 20.8219 31.0379 21.9685 32.4452 21.9685C33.8785 21.9685 35.0252 20.8219 35.0252 19.3885C35.0252 17.9552 33.8524 16.8086 32.4452 16.8086C31.0119 16.8086 29.8652 17.9813 29.8652 19.3885ZM39.1679 27.7537C37.7345 27.7537 36.5879 26.607 36.5879 25.1737C36.5879 23.7665 37.7606 22.5938 39.1679 22.5938C40.5751 22.5938 41.7478 23.7404 41.7478 25.1737C41.7478 26.581 40.6012 27.7537 39.1679 27.7537Z"
      fill="currentColor"
    />
  </svg>
);

export default OnePercent;
