import * as React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { FormattedMessage, useIntl } from "react-intl";
import IframeResizer from "iframe-resizer-react";
import Link from "./link";
import OnePercent from "./one-percent";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const Booking = ({ url, children }) => {
  const { formatMessage } = useIntl();
  const urlParts = url?.split("/");
  const productId = urlParts?.[3];
  const iframeEl = React.useRef(null);

  return (
    <section id="booking" className="booking" aria-labelledby="booking-heading">
      <div className="container">
        <div className="booking-content">
          <h2 id="booking-heading" className="heading">
            <FormattedMessage id="bookingHeading" />
          </h2>
          {children && renderRichText(children, renderOptions)}
          <div className="booking-one-percent">
            <OnePercent />
            <p>
              <FormattedMessage id="bookingOnePercent" />
            </p>
          </div>
        </div>
        <div className="booking-widget">
          {productId && (
            <IframeResizer
              forwardRef={iframeEl}
              seamless
              width={300}
              height={480}
              frameBorder={0}
              className="rezdy"
              src={`https://gravelup.rezdy.com/calendarWidget/${productId}?iframe=true`}
              title={formatMessage({ id: "book" })}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Booking;
