import * as React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useIntl } from "react-intl";
import slugify from "slugify";
import Link from "./link";
import ImageWithText from "../components/image-with-text";

const slugOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { image, mobileImage, imageAlt, text } = node.data.target || {};

      if (image) {
        return (
          <ImageWithText
            image={image}
            mobileImage={mobileImage}
            imageAlt={imageAlt}
            text={text}
          />
        );
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },
};

const Preparation = ({ preparation }) => {
  const { formatMessage } = useIntl();

  if (preparation) {
    return (
      <section
        id={slugify(formatMessage({ id: "preparation" }), slugOptions)}
        className="preparation"
        aria-labelledby="preparation-heading"
      >
        <div className="container">
          <div>
            <h2 id="preparation-heading" className="heading">
              {formatMessage({ id: "preparation" })}
            </h2>
            {renderRichText(preparation, renderOptions)}
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export default Preparation;
