import * as React from "react";
import { FormattedMessage } from "react-intl";

const Benefits = ({ product }) => {
  const {
    includedAtTheCamp,
    includedOnGravel,
    includedBeyondGravel,
    notIncluded,
  } = product;

  if (
    includedAtTheCamp &&
    includedOnGravel &&
    includedBeyondGravel &&
    notIncluded
  ) {
    return (
      <section className="benefits" aria-labelledby="benefits-heading">
        <div className="container">
          <div className="benefits-content">
            <h2 id="benefits-heading" className="visually-hidden">
              <FormattedMessage id="benefits" />
            </h2>
            <div className="benefits-grid">
              <div>
                <h3 className="heading">
                  <FormattedMessage id="benefitsIncluded" />
                </h3>
                <h4>
                  <FormattedMessage id="benefitsIncludedAtTheCamp" />
                </h4>
                <ul className="benefits-included">
                  {includedAtTheCamp.map((includedItem) => (
                    <li key={includedItem}>{includedItem}</li>
                  ))}
                </ul>
                <h4>
                  <FormattedMessage id="benefitsIncludedOnGravel" />
                </h4>
                <ul className="benefits-included">
                  {includedOnGravel.map((includedItem) => (
                    <li key={includedItem}>{includedItem}</li>
                  ))}
                </ul>
                <h4>
                  <FormattedMessage id="benefitsIncludedBeyondGravel" />
                </h4>
                <ul className="benefits-included">
                  {includedBeyondGravel.map((includedItem) => (
                    <li key={includedItem}>{includedItem}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="heading">
                  <FormattedMessage id="benefitsNotIncluded" />
                </h3>
                <ul className="benefits-not-included">
                  {notIncluded.map((notIncludedItem) => (
                    <li key={notIncludedItem}>{notIncludedItem}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export default Benefits;
